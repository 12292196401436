'use strict';

angular.module('appApp')
  .controller('SubmitIngredientCtrl', function ($scope, $http, $location, $state, $stateParams, Auth, Modal) {
    $scope.vars = {
      multilingual: $stateParams.multilingual === 'true',
      file: {
        user: Auth.getCurrentUser().email,
        date: moment().format('DD/MM/YYYY'),
        id: 'ingredient-submission'
      },
      imgFile: {
        id: 'ingredient-img'
      },
      imgFileData: '',
      ingredient: {
        user: Auth.getCurrentUser().email,
        date: moment().format('DD/MM/YYYY'),
        name: '',
        nameD: '',
        nameF: '',
        nameI: '',
        nameE: '',
        tipo: 'Ingrediente',
        productId: '',
        manufacturer: '',
        category: '',
        categoryD: '',
        categoryF: '',
        categoryI: '',
        categoryE: '',
        ingredients: '',
        traces: {},
        allergens: '',
        allergenic: 'No',
        description: '',
        energy: 0,
        energykJ: 0,
        fats: 0,
        satFats: 0,
        monoinsatFats: 0,
        polyinsatFats: 0,
        carbohydrates: 0,
        sugars: 0,
        polyol: 0,
        starch: 0,
        fibers: 0,
        proteins: 0,
        salts: 0,
        aromaInfoDichiarazione: '',
        aromaAdditiviSolventiAltro: '',
        multilingual: $stateParams.multilingual === 'true'
      },
      traceLabels: {
        sedano: 'Sedano',
        crostacei: 'Crostacei',
        uova: 'Uova',
        pesce: 'Pesce',
        glutine: 'Glutine',
        lupini: 'Lupini',
        latte: 'Latte',
        molluschi: 'Molluschi',
        senape: 'Senape',
        fruttiAGuscioNoci: 'Frutti a guscio / Noci',
        arachidi: 'Arachidi',
        semiDiSesamo: 'Semi di sesamo',
        soia: 'Soia',
        anidrideSolfosaSolfiti: 'Anidride solfosa / Solfiti'
      },
      additivesInfo: []
    };

    /**************************************************************************/

    $http.get('/assets/data/additivi.json').then(res => {
      $scope.vars.additivesInfo = res.data;
    });

    /**************************************************************************/

    $scope.editTraces = function() {
      Modal.traces.edit($scope.vars.ingredient.traces, (traces) => {
        if(!$scope.vars.ingredient.traces) { $scope.vars.ingredient.traces = traces; }
      })();
    };

    $scope.isLastSelectedItemInObject = function(testItem, object) {
      var lastItem = '';

      for(var item in object) {
        if(object[item]) { lastItem = item; }
      }

      return lastItem === testItem;
    };

    /**************************************************************************/

    $scope.flowUploadStarted = function($flow, model) {
      progressJs('#' + model.id + ' .upload-progress').start().autoIncrease(2, 500);
      model.state = 'started';
      model.value = $flow.files[0].name;
    };

    $scope.flowFileProgress = function($flow, model) {
      if($flow.files[0]) {
        progressJs('#' + model.id + ' .upload-progress').set($flow.files[0].progress() * 100);
      }
    };

    $scope.flowUploadComplete = function($flow, model) {
      progressJs('#' + model.id + ' .upload-progress').end();
      if($flow.files[0]) {
        model.state = 'completed';
        model.uniqueIdentifier = $flow.files[0].uniqueIdentifier;

        if($scope.vars.imgFile.uniqueIdentifier) {
          $scope.loadImgData();
        }
      } else {
        $scope.cancelUpload($flow, model);
      }
    };

    $scope.flowUploadFile = function($flow) {
      $flow.upload();
    };

    $scope.cancelUpload = function($flow, model) {
      progressJs('#' + model.id + ' .upload-progress').end();
      if($flow.files[0]) { $flow.files[0].cancel(); }
      model.value = model.state = model.uniqueIdentifier = '';
      $scope.vars.imgFileData = '';
    };

    $scope.deleteUploaded = function($flow, model) {
      if($flow.files[0]) { $flow.files[0].cancel(); }
      $http.get('/api/filetransfers/delete/' + model.uniqueIdentifier);
      model.value = model.state = model.uniqueIdentifier = '';
      $scope.vars.imgFileData = '';
    };

    /**************************************************************************/

    $scope.getEmptyFields = function(edit) {
      var emptyFields = [];
      if(!$scope.vars.ingredient.multilingual) {
        if(!$scope.vars.ingredient.name) { emptyFields.push('Denominazione'); }
        if(!$scope.vars.ingredient.category) { emptyFields.push('Categoria'); }
      } else {
        if(!$scope.vars.ingredient.nameI) { emptyFields.push('Denominazione italiana'); }
        if(!$scope.vars.ingredient.nameE) { emptyFields.push('Denominazione inglese'); }
        if(!$scope.vars.ingredient.nameD) { emptyFields.push('Denominazione francese'); }
        if(!$scope.vars.ingredient.nameF) { emptyFields.push('Denominazione tedesca'); }
        if(!$scope.vars.ingredient.categoryI) { emptyFields.push('Categoria italiana'); }
        if(!$scope.vars.ingredient.categoryE) { emptyFields.push('Categoria inglese'); }
        if(!$scope.vars.ingredient.categoryF) { emptyFields.push('Categoria francese'); }
        if(!$scope.vars.ingredient.categoryD) { emptyFields.push('Categoria tedesca'); }
      }
      if($scope.vars.ingredient.tipo !== 'Ingrediente' &&
        !$scope.vars.ingredient.productId) { emptyFields.push('Codice prodotto'); }
      if($scope.vars.ingredient.tipo !== 'Ingrediente' &&
        !$scope.vars.ingredient.manufacturer) { emptyFields.push('Produttore'); }
      if($scope.vars.ingredient.tipo === 'Aroma' &&
        !$scope.vars.ingredient.aromaInfoDichiarazione) { emptyFields.push('Dichiarazione in etichetta...'); }
      if(($scope.vars.ingredient.tipo === 'Semilavorato' ||
        $scope.vars.ingredient.tipo === 'Aroma') &&
        !$scope.vars.ingredient.ingredients) { emptyFields.push('Ingredienti'); }
      if(!$scope.vars.ingredient.description) { emptyFields.push('Descrizione'); }
      if(!edit && !$scope.vars.file.value) { emptyFields.push('Scheda tecnica prodotto'); }
      return emptyFields.join(', ');
    };

    $scope.createIngredientObject = () => {
      var newIngredient = {
        id: $scope.vars.edit ? $scope.vars.ingredient.id : null,
        titolo: $scope.vars.ingredient.name,
        titoloD: $scope.vars.ingredient.nameD,
        titoloF: $scope.vars.ingredient.nameF,
        titoloI: $scope.vars.ingredient.nameI,
        titoloE: $scope.vars.ingredient.nameE,
        tipo: $scope.vars.ingredient.tipo,
        descrizione: $scope.vars.ingredient.description,
        produttore: $scope.vars.ingredient.manufacturer,
        energiaRicalcolataKcal: $scope.vars.ingredient.energy,
        energiaRicalcolataKJ: $scope.vars.ingredient.energykJ,
        lipidiTotaliG: $scope.vars.ingredient.fats,
        lipidiAnimaliG: $scope.vars.ingredient.satFats,
        grassiMonoinsaturiG: $scope.vars.ingredient.monoinsatFats,
        grassiPolinsaturiG: $scope.vars.ingredient.polyinsatFats,
        carboidratiDisponibiliMSEG: $scope.vars.ingredient.carbohydrates,
        carboidratiSolubiliMSEG: $scope.vars.ingredient.sugars,
        polioliG: $scope.vars.ingredient.polyol,
        amidoG: $scope.vars.ingredient.starch,
        fibraAlimentareTotaleG: $scope.vars.ingredient.fibers,
        proteineTotaliG: $scope.vars.ingredient.proteins,
        sodioMg: $scope.vars.ingredient.salts / 2.5,
        allergenico: $scope.vars.ingredient.allergenic === 'Sì',
        ingredienti: $scope.vars.ingredient.ingredients,
        parteAllergenica: $scope.vars.ingredient.allergenParts,
        listaAllergeni: $scope.vars.ingredient.allergens,
        listaTracce: $scope.vars.ingredient.traces,
        codice: $scope.vars.ingredient.productId,
        categoria: $scope.vars.ingredient.category,
        categoriaD: $scope.vars.ingredient.categoryD,
        categoriaF: $scope.vars.ingredient.categoryF,
        categoriaI: $scope.vars.ingredient.categoryI,
        categoriaE: $scope.vars.ingredient.categoryE,
        user: $scope.vars.ingredient.user,
        aromaInfoDichiarazione: $scope.vars.ingredient.aromaInfoDichiarazione,
        aromaAdditiviSolventiAltro: $scope.vars.ingredient.aromaAdditiviSolventiAltro,
        source: $scope.vars.source,
        multilingual: $scope.vars.multilingual
      };

      if($scope.vars.ingredient.tipo === 'Semilavorato' ||
          $scope.vars.ingredient.tipo === 'Aroma') {
        newIngredient.ingredienti = newIngredient.ingredienti.split(',')
          .map(e => e.trim()).filter(e => e);
        newIngredient.listaAllergeni = newIngredient.listaAllergeni ?
          newIngredient.listaAllergeni.split(',').map(e => e.trim())
          .filter(e => e) : [];
        newIngredient.allergenico = !!newIngredient.listaAllergeni.length;
        newIngredient.parteAllergenica = null;
      }

      if($scope.vars.ingredient.tipo === 'Ingrediente' ||
          $scope.vars.ingredient.tipo === 'Additivo') {
        newIngredient.ingredienti = [];
        newIngredient.listaAllergeni = [];
      }

      if($scope.vars.ingredient.tipo === 'Aroma') {
        newIngredient.aromaAdditiviSolventiAltro =
          newIngredient.aromaAdditiviSolventiAltro ?
          newIngredient.aromaAdditiviSolventiAltro.split(',')
          .map(e => e.trim()).filter(e => e) : [];
      } else {
        newIngredient.aromaAdditiviSolventiAltro = [];
      }

      if($scope.vars.imgFile.uniqueIdentifier) {
        newIngredient.imgFile = $scope.vars.imgFile;
      }

      return newIngredient;
    };

    $scope.validateIngredient = (ingredient) => {
      var invalidFields = [];
      if(!ingredient.multilingual) {
        if(!ingredient.titolo) { invalidFields.push('Denominazione'); }
        if(!ingredient.categoria) { invalidFields.push('Categoria'); }
      } else {
        if(!ingredient.titoloI) { invalidFields.push('Denominazione italiana'); }
        if(!ingredient.titoloE) { invalidFields.push('Denominazione inglese'); }
        if(!ingredient.titoloF) { invalidFields.push('Denominazione francese'); }
        if(!ingredient.titoloD) { invalidFields.push('Denominazione tedesca'); }
        if(!ingredient.categoriaI) { invalidFields.push('Categoria italiana'); }
        if(!ingredient.categoriaE) { invalidFields.push('Categoria inglese'); }
        if(!ingredient.categoriaF) { invalidFields.push('Categoria francese'); }
        if(!ingredient.categoriaD) { invalidFields.push('Categoria tedesca'); }
      }
      if(ingredient.tipo !== 'Ingrediente' &&
        !ingredient.codice) { invalidFields.push('Codice prodotto'); }
      if(ingredient.tipo === 'Aroma' && !ingredient.aromaInfoDichiarazione) {
        invalidFields.push('Dichiarazione in etichetta...'); }
      if((ingredient.tipo === 'Semilavorato' || ingredient.tipo === 'Aroma') &&
        !ingredient.ingredienti.length) { invalidFields.push('Ingredienti'); }
      if($scope.isNotNumeric(ingredient.energiaRicalcolataKcal)) {
        invalidFields.push('Energia (kcal)'); }
      if($scope.isNotNumeric(ingredient.energiaRicalcolataKJ)) {
        invalidFields.push('Energia (kJ)'); }
      if($scope.isNotNumeric(ingredient.lipidiTotaliG)) {
        invalidFields.push('Grassi'); }
      if($scope.isNotNumeric(ingredient.lipidiAnimaliG)) {
        invalidFields.push('acidi grassi saturi'); }
      if($scope.isNotNumeric(ingredient.grassiMonoinsaturiG)) {
        invalidFields.push('acidi grassi monoinsaturi'); }
      if($scope.isNotNumeric(ingredient.grassiPolinsaturiG)) {
        invalidFields.push('acidi grassi polinsaturi'); }
      if($scope.isNotNumeric(ingredient.carboidratiDisponibiliMSEG)) {
        invalidFields.push('Carboidrati'); }
      if($scope.isNotNumeric(ingredient.carboidratiSolubiliMSEG)) {
        invalidFields.push('zuccheri'); }
      if($scope.isNotNumeric(ingredient.polioliG)) {
        invalidFields.push('polioli'); }
      if($scope.isNotNumeric(ingredient.amidoG)) {
        invalidFields.push('amido'); }
      if($scope.isNotNumeric(ingredient.fibraAlimentareTotaleG)) {
        invalidFields.push('Fibre'); }
      if($scope.isNotNumeric(ingredient.proteineTotaliG)) {
        invalidFields.push('Proteine'); }
      if($scope.isNotNumeric($scope.vars.ingredient.salts) ||
        $scope.isNotNumeric(ingredient.sodioMg)) {
        invalidFields.push('Sale'); }
      return invalidFields.join(', ');
    };

    $scope.isNotNumeric = (n) => {
      return (!n && n !== 0) || !n.toString().match(/^\d*(\.\d+)?$/);
    };

    $scope.showTermsForInsertion = function(edit) {
      var emptyFields = $scope.getEmptyFields(edit);
      if(emptyFields) {
        var msg = [ 'Riempire tutti i campi per poter proseguire.',
          'Campi mancanti:', emptyFields ];
        return alert(msg.join(' '));
      }

      var newIngredient = $scope.createIngredientObject();
      var invalidFields = $scope.validateIngredient(newIngredient);
      if(invalidFields) {
        var msg2 = [ 'Correggere i campi per poter proseguire.',
          'Campi invalidi:', invalidFields ];
        return alert(msg2.join(' '));
      }

      Modal.acceptTermsForIngredientSubmission.show(() => {
        $scope.submitIngredient(newIngredient, edit);
      })();
    };

    $scope.submitIngredient = function(newIngredient, edit) {
      if($scope.vars.ingredient.user === 'demo@primolabel.it') {
        const demoMsg = 'Non è possibile ' +
          (edit ? 'modificare' : 'inserire') +
          ' ingredienti con l\'utente demo';
        alert(demoMsg);
        return;
      }

      if(edit) {
        $scope.delete($stateParams.ingredient, () => {
          $scope.save(newIngredient, edit);
        });
      } else {
        $scope.save(newIngredient, edit);
      }
    };

    $scope.save = (newIngredient, edit) => {
      $http({
        url: '/api/alimenti/', method: 'POST', data: newIngredient
      }).then(() => {
        const msg = !edit ? 'Ingrediente inserito con successo' :
          'Ingrediente modificato con successo';
        const file = !edit ? $scope.vars.file : null;
        const data = { ingredient: $scope.vars.ingredient, file: file  };
        alert(msg);
        $http({
          url: '/api/sendEmails/submitIngredient/', method: 'POST', data: data
        });
        $scope.goBack();
        // $location.path('/');
      }).catch(err => {
        const msg = !edit ? 'Errore durante l\'inserimento. Riprovare più tardi' :
          'Errore durante la modifica. Riprovare più tardi';
        console.log(err);
        alert(msg);
      });
    };

    $scope.delete = (id, cb) => {
      $http({
        url: '/api/alimenti/' + id, method: 'DELETE'
      }).then(cb).catch(err => {
        console.log(err);
        alert('Errore durante la modifica. Riprovare più tardi');
      });
    };

    $scope.range = (min, max, step) => {
      step = step || 1;
      var input = [];
      for (var i = min; i <= max; i += step) {
        input.push(i);
      }

      return input;
    };

    $scope.additiveInfoChanged = () => {
      const name = $scope.vars.ingredient.additiveClass.label + ': E' +
        $scope.vars.ingredient.additiveNumber + ' - ' +
        $scope.vars.ingredient.additiveSubClass.label;
      $scope.vars.ingredient.name = name;
    };

    /**************************************************************************/

    $scope.vars.isAdmin = Auth.getCurrentUser().role === 'admin';

    if($stateParams.ingredient) {
      $http.get('/api/alimenti/find/' + $stateParams.ingredient).then(res => {
        const data = res.data;

        $scope.vars.ingredient = {
          id: data.id,
          name: data.titolo,
          nameD: data.titoloD,
          nameF: data.titoloF,
          nameI: data.titoloI,
          nameE: data.titoloE,
          tipo: data.tipo || 'Ingrediente',
          description: data.descrizione,
          manufacturer: data.produttore,
          energy: data.energiaRicalcolataKcal,
          energykJ: data.energiaRicalcolataKJ,
          fats: data.lipidiTotaliG,
          satFats: data.lipidiAnimaliG,
          monoinsatFats: data.grassiMonoinsaturiG,
          polyinsatFats: data.grassiPolinsaturiG,
          carbohydrates: data.carboidratiDisponibiliMSEG,
          sugars: data.carboidratiSolubiliMSEG,
          polyol: data.polioliG,
          starch: data.amidoG,
          fibers: data.fibraAlimentareTotaleG,
          proteins: data.proteineTotaliG,
          salts: data.sodioMg * 2.5,
          allergenic: data.allergenico ? 'Sì' : 'No',
          ingredients: data.ingredienti.join(', '),
          allergenParts: data.parteAllergenica,
          allergens: data.listaAllergeni.join(', '),
          traces: data.listaTracce,
          productId: data.codice,
          category: data.categoria,
          categoryD: data.categoriaD,
          categoryF: data.categoriaF,
          categoryI: data.categoriaI,
          categoryE: data.categoriaE,
          user: data.user,
          aromaInfoDichiarazione: data.aromaInfoDichiarazione,
          aromaAdditiviSolventiAltro: data.aromaAdditiviSolventiAltro.join(', '),
          multilingual: data.multilingual === 'true'
        };

        // if(data.tipo === 'Aroma') {
        //   const nameSplit1 = data.titolo.split(': E');
        //   const nameSplit2 = nameSplit1[1].split(' - ');
        //   $scope.vars.ingredient.additiveClass.label = nameSplit1[0];
        //   $scope.vars.ingredient.additiveNumber = nameSplit2[0];
        //   $scope.vars.ingredient.additiveSubClass.label = nameSplit2[1];
        // }

        $scope.vars.multilingual = data.multilingual === 'true';
        $scope.vars.edit = true;
        $scope.vars.editable =
          Auth.getCurrentUser().email === data.user || $scope.vars.isAdmin;
        $scope.vars.source = data.source || (data.id < 1000 ?
          'Unità di Chimica degli Alimenti dell’INRAN e/o accurata selezione ' +
          'bibliografica prevalentemente italiana' :
          'Scheda tecnica del prodotto');
        $scope.vars.imgFile = data.imgFile || $scope.vars.imgFile;

        if($scope.vars.imgFile.uniqueIdentifier) {
          $scope.loadImgData();
        }
      });
    }

    $scope.goBack = () => {
      $state.go('ingredients-list', {
        pageNumber: $stateParams.pageNumber,
        pageSize: $stateParams.pageSize,
        filtered: $stateParams.filtered
      });
    };

    $scope.deleteThis = () => {
      Modal.confirm.delete(() => {
        $scope.delete($stateParams.ingredient, () => {
          $scope.goBack();
        });
      })('questo ingrediente');
    };

    $scope.loadImgData = () => {
      if($scope.vars.imgFile.uniqueIdentifier) {
        const id = $scope.vars.imgFile.uniqueIdentifier;
        const name = $scope.vars.imgFile.value;
        const url = '/api/filetransfers/download/' + id + '/' + name;
        const config = { responseType: 'arraybuffer' };

        $http.get(url, config).then(res => {
          $scope.vars.imgFileData = 'data:image/xyz;base64,' +
            $scope._arrayBufferToBase64(res.data);
        }).catch(err => {
          console.log(err);
          alert('Errore durante il caricamento dell\'immagine');
        });
      }
    };

    $scope._arrayBufferToBase64 = function( buffer ) {
      var binary = '';
      var bytes = new Uint8Array( buffer );
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
      }
      return window.btoa( binary );
    };

    $scope.convert = function() {
      $scope.vars.ingredient.multilingual = true;
      $scope.vars.ingredient.nameI = $scope.vars.ingredient.name;
      $scope.vars.ingredient.name = '';
      $scope.vars.ingredient.categoryI = $scope.vars.ingredient.category;
      $scope.vars.ingredient.category = '';
      $scope.vars.edit = false;
      $scope.vars.multilingual = true;
    };

  });
